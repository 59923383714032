import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TonConnectUIProvider} from "@tonconnect/ui-react";
import ReactDOM from "react-dom/client";
import Page from "./page";
import "./index.css";
// this manifest is used temporarily for development purposes
const manifestUrl = "https://cdn.onetime.dog/manifest.json";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false }},
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <TonConnectUIProvider 
  manifestUrl={manifestUrl}
  //uiPreferences={{ theme: THEME.DARK }}
   
    actionsConfiguration={{
        twaReturnUrl: 'https://t.me/fliprewards_bot/pool'
    }}
  >
    <QueryClientProvider client={queryClient}>
      <Page />
    </QueryClientProvider>
  </TonConnectUIProvider>
);

window.addEventListener("contextmenu", function (e) {
	e.preventDefault();
});